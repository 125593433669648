@import "../../imports";

.TagFilter {
  width: 400px;

  .FilterRow {
    display: flex;
    flex: 1 1 auto;
    color: $primary;
    justify-content: flex-start;
    align-items: center;
    margin: 12px 0;

    .tag {
      flex: 1 0 110px;
    }
    .operation {
      flex: 0 0 100px;
    }
    .value {
      flex: 1 0 110px;
    }
    .remove {
      box-shadow: none;
      margin: 0;
    }

    .Select.variant-primary {
      margin: 0 4px;
      font-size: 12px;

      .FulcrumSelect__control {
        padding: 6px;
      }
      .FulcrumSelect__indicator-separator {
        display: none;
      }
      .FulcrumSelect__dropdown-indicator {
        padding: 0;
      }
    }
    .Input {
      margin: 0 4px;
      padding: 12px;
      font-size: 12px;
    }
    .remove {

    }
  }

}

