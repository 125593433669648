@import "../../imports";

$board-column-width: $card-width !default;
.TagBoard {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .TagBoardCard {

  }

  .board {
    position: relative;
    flex: 1 1 auto;
    overflow: auto;
    z-index: 0;

    .board-headers {
      display: flex;
      flex: 0 0 auto;
      position: sticky;
      top: 0;
      z-index: 1;

      .column-header {
        flex: 0 0 $board-column-width;
        border-bottom: 1px solid $accent-green;
        padding: 16px 16px 10px 16px;
        margin: 0 32px 12px 0;
        background: $background-dark;

        label {
          display: flex;
          justify-content: space-between;
          font-size: $font-size-smallest;
          color: $accent-green;
        }
      }
    }
    .board-columns {
      display: flex;
      flex: 1 1 auto;
    }
    .column {
      display: flex;
      flex-direction: column;
      flex: 0 0 $board-column-width;

      margin: 16px 32px 0 0;
      &:last-child {
        margin-right: 0;
      }


      .column-content {
        margin-bottom: 40px;

        &:hover {
          background: rgba($primary, 0.025);
        }

        &.dragging {
          position: relative;
          transition: all .2s ease-in;
          background: $dark;

          &:hover {
            background: rgba($primary, 0.1);
          }

          .EntityCard {
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);
          }
        }
      }

    }
    .column-content {
      flex: 1 1 auto;
    }

    .card-wrapper {
      position: relative;
      > .Badge {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  &.inset {
    .board {
      padding: 0 30px;
    }
    .TagViewControls {
      padding: 24px;
    }
  }
}

