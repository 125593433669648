@import '../imports';

.SiteSection {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  background: $background-dark;
  height: 100vh;
}
