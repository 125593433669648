@import '../../imports';

.SegmentTags {

  .AddTag {
    align-self: center;
  }

  .TagList {
    &.editing {
      .TagRow {
        padding: 8px 0px;
      }
    }
  }

}
