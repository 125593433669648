@import "../../imports";

.AboutPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 0 auto;

  .AboutPage-header {
    min-height: 90vh;

    background-size: 100vw;

    @include media('portrait') {
      background-size: auto 100vh;
    }

    background-size: cover;

    background-position: center;
    background-attachment: fixed;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 100px 100px;
      @include Content-Page-Header;

    }

  }


  .AboutPage-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: visible;
    padding-bottom: 80px;
    //margin-bottom: -20px;
  }

  .timeline {
    margin-top: 120px;
    @include timeline;
    height: 16px;

  }

  .Footer {
    display: flex;
    flex-direction: column;

    align-items: center;
    margin-top: 120px;

    > * {
      max-width: 560px;
    }

    h2 {
      line-height: 1.5;
      @include Heading-Text;
      font-weight: normal;
      strong {
        font-weight: normal;
      }
    }

    .Button {
      background: $dark-grey-blue;
    }

    text-align: center;
    margin-bottom: 120px;

    .Form {
      padding: 0px;
      width: 560px;

      margin-top: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      * {
        color: $off-white !important;
      }

      label {
        @include Label-Text;
      }
      .Input {
        border: 1px solid transparent;

        font: inherit;
        background: transparent;
        color: $off-white;
        margin-top: -36px;
        padding-top: 36px;

        &::placeholder {
          color: $off-white;
          font-style: italic;
        }

        &:focus {
          border-color: $dark-grey-blue;
        }
      }
      .Button {
        width: 160px;
        position: absolute;
        right: 16px;
        bottom: 32px;
        &:hover {
          background: $cadet-blue;
        }
      }
      .FormRow {
        text-align: left;
        flex: 1;

      }
    }
  }

  .arrow {
    $arrow-border: solid 1px rgba($off-white, 0.5);
    flex: 1 0 10px;
    width: 0;
    //background: $off-white;
    border-left: $arrow-border;
    position: relative;

    .arrow-tail {
      $tail-size: 9px;
      position: absolute;
      width: $tail-size;
      height: $tail-size;
      border-radius: $tail-size;
      top: -$tail-size;
      left: -$tail-size/2;
      border: $arrow-border;
    }

    .arrow-head {
      position: absolute;
      top: 100%;
      height: 61px;
      width: 0;
      left: -1px;
      border-left: $arrow-border;
      img {
        position: absolute;
        bottom: calc(100% - 61px);
        left: -5px;
      }
    }
  }

  @include media("≤850px") {

    .AboutPage-header {
      background-attachment: scroll;
      h1 {
        @include Heading-Text;
        margin: 40px;
        margin-top: 80px;
      }
    }
    .timeline {
      margin-top: 80px;
    }

    .Footer {
      margin-top: 80px;
      padding: 0 40px;

      h2 {
        @include Mobile-Header-Text;
        margin-top: 0;
      }

      .Form {
        width: 100%;
        .Label {
          text-align: center;
        }
        .Input {
          padding-bottom: 70px;
        }
        .Button {
          left: 12px;
          right: 12px;
          width: auto;
        }
      }
    }
  }

  .legal-footer {
    background: rgba(black, 0.5);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    padding: 24px;
    > * {
      min-width: 300px;
      a {
        color: $off-white;
      }
      flex: 1;
      text-align: center;
    }
  }
}