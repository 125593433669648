@import "../../imports";

.Interview {
  .ResearchItemData {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 40px;
    height: 100%;

    > .content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      padding-bottom: 24px;
      max-width: 100%;
    }

  }
  .Interview-player {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex: 1 1 100%;
    height: 100%;
    background-color: $dark-grey-blue;

    .player-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1 auto;
    }

    > div:first-child {
      flex: 1;
    }

    &:hover {
      .transport-controls-wrapper > * {
        opacity: 1;
      }
    }
    user-select: none;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      margin: 0 8px;
    }

  }

  .transport-controls-wrapper {
    position: absolute;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > * {
      opacity: 0;
      transition: opacity 0.3s;
    }

  }


  .transport-controls {
    border-radius: 1px;
    border: solid 0.5px $cadet-blue;
    padding: 8px 20px;
    background: $dark-grey-blue;

    display: flex;
    flex-direction: row;
    align-items: center;

  }

  .columns > *:last-child > .content {
    flex-basis: 0;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 0;
  }

  .audio-track-picker {
    padding: 8px 16px;
    background-color: $cadet-blue;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 5px;

    img {
      margin-right: 16px;
    }
    button {
      width: 24px;
      height: 24px;
      border: 1px solid white;
      border-radius: 24px;
      margin-right: 8px;
      color: white;

      background-color: $cadet-blue;

      &[disabled] {
        cursor: default;
        background-color: $off-white;
        color: $cadet-blue;
        font-weight: bold;
      }
    }
  }

  .loop-button {
    width: 40px;
    height: 40px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);
    background-color: $cadet-blue;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: 16px;

    button {
      margin: 0;
      padding: 0;
      margin-top: 4px;
    }
  }

  &.SplitPane {
    .Pane {
      overflow-x: hidden;
      overflow-y: auto;
    }
    .Pane2 {

    }
  }


  .Resizer {
    $color: $primary;
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;
    background: $color;
    opacity: 0.5;

    &:hover {
      transition: all 0.225s ease;
      opacity: 1;
    }

    &.horizontal {
      height: 4px;
      cursor: row-resize;
      width: 100%;
    }


    &.vertical {
      width: 4px;
      cursor: col-resize;
    }

    &.disabled {
      cursor: not-allowed;
    }
    &.disabled:hover {
      border-color: transparent;
    }
  }


}