
@import "../node_modules/include-media/dist/_include-media.scss";
@import "./zeplin";

@import url('https://fonts.googleapis.com/css?family=Prata|Raleway:300,300i,500|Roboto+Mono');


$breakpoints: (phone: 597px, tablet: 768px, desktop: 1024px);
$primary-text: #72b6ce;
$primary: #487d8f;
$secondary: #29454e;
$neutral: #e5e0da;
$light: #ffffff;
$error: #d979a3;
$dark: #0b262f;
$background: $dark;
$background-dark: #01232c;
$text: $light;
$accent-green: #9dc47e;
$black: #092129;

$card-width: 240px;

$font-family: Raleway, sans-serif;

$playhead-stripe-color: transparentize(#c7b299, 0.8);

@mixin timeline {
  background-image: linear-gradient(90deg, $playhead-stripe-color 8.33%, #0b262f 8.33%, #0b262f 50%, $playhead-stripe-color 50%, $playhead-stripe-color 58.33%, #0b262f 58.33%, #0b262f 100%);
  background-size: 12.00px 12.00px;
}
@import "../node_modules/@upgrowth/react-fulcrum/lib/defaults.scss";
@import "../node_modules/@upgrowth/react-fulcrum/lib/mixins.scss";

$palette-black: generate-palette($black, $light);
//
//$palette-dark: (
//  normal: (
//    dark: $dark,
//    background: linear-gradient(-180deg, $primary 0%, $dark 100%),
//  ),
//    focus: (
//    dark: $dark,
//    background: mix($dark, $light, 80%),
//  ),
//    active: (
//    dark: mix($dark, $light, 60%),
//    background: mix($dark, $light, 60%),
//  ),
//    hover: (
//    dark: $dark,
//    background: mix($dark, $light, 70%),
//  ),
//    disabled: (
//    dark: rgba($dark, 0.7),
//    background: mix($dark, $light, 80%),
//  )
//);
