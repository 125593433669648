@import "../../imports";

.TagViewControls {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding: 32px 0 16px 0;

  .SimpleDropdown {
    display: inline-flex;
    margin: 0 4px;

    .dropdown__trigger {
      display: inline-flex;
      align-items: center;
      .Icon {
        margin: 0 4px 0 -8px;
        font-size: 18px;
        color: $primary;
        stroke-width: 2px;
      }
    }
    .dropdown__content {
      min-width: 240px;
    }
  }

  > .Button {
    padding: 8px;
    font-size: 12px;
    min-height: 0;
  }
  > .Select {
    display: inline-flex;
    flex: 0 0 120px;
    margin: 4px;
  }

  .ActionsDropdown {
    margin: 0 6px;
  }

  .AddButton.variant-primary {
    margin: 0;
    font-size: 18px;
    padding-top: 9px;
    padding-bottom: 9px;
    .Icon{ stroke-width: 2px; }
  }
}

.ActionsDropdown {
}

.ColumnsDropdown {
  align-self: stretch;

  .dropdown__indicator {
    margin-left: 0 !important;
    stroke: $primary;
  }
  &.filtered .dropdown__indicator{
    fill: $primary;
    stroke: $secondary;
  }
  .dropdown__content {
    right: 0;

    .reset {
      display: inline-flex;
      align-self: flex-end;
      cursor: pointer;
      margin-bottom: 12px;
      color: $accent-green;
    }
    .Switch {
      margin: 0 0 12px 0;
      padding: 0;
      font-size: 14px;
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
      color: $neutral;

      .react-toggle {
        margin-right: 0;
        .react-toggle-track {
          background: $dark;
          border-color: $secondary;
        }
      }
    }
  }
}

.FilteredCount {
  margin-right: 16px;
}

.FilterDropdown {
  &.filtered {
    .dropdown__trigger .Icon {
      fill: $primary;
      stroke: $secondary;
    }
  }
  .dropdown__content {
    right: 0;
  }
}
.GroupByDropdown {
  a {
    white-space: pre;
  }
}
.ExtraActions {
  align-self: stretch;
  margin-left: 0 !important;

  .dropdown__trigger {
    color: white;
    stroke-width: 2px;
  }
  .dropdown__content {
    right: 0;
  }
}



