@import "../../imports";

.Team {
  .Avatar {
    button {
      font-size: 0;
      opacity: 0;
      transition: opacity 0.3s;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0;

      border-bottom-left-radius: 10px;
      background-color: $cadet-blue;
    }

    &:hover {
      transform: none;
      button {
        opacity: 1;
      }
    }
  }
}