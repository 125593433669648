@import '../../imports';

$bar-height: 56px;


.ScrubBar {
  display: flex;
  flex-direction: row;
  height: $bar-height;
  align-items: center;

  user-select: none;

  .ScrubBar-bar {
    flex: 1 1 100%;
    height: 100%;
    position: relative;
    //background-image: url("./timeline.svg");
    //background-repeat: repeat;
    //background-image: linear-gradient(90deg, #487d8f 2.38%, #0b262f 2.38%, #0b262f 50%, #487d8f 50%, #487d8f 52.38%, #0b262f 52.38%, #0b262f 100%);
    //background-size: 42.00px 42.00px;
    @include timeline
  }

  .time {
    cursor: pointer;
    flex: 0 0 100px;
    text-align: center;
    padding: 0 20px;
    @include Scrubber-Text;
  }

  .head {
    height: 100%;
    width: 0px;
    //border-left: 1px solid black;
    //outline: 4px solid blue;
    position: absolute;
    top: 0;
    //margin-left: -12px;
    transition: left 100ms linear;

    > img {
      position: absolute;
      bottom: 0;
      margin-left: -27px;
    }

    &.playhead {
      > img {
        margin-left: -21px;

      }
      //border-color: blue;
    }

    &.starthead {
      //border-color: green;
    }

    &.endhead {
      //border-color: red;
    }
  }

}