@import "../imports.scss";
@import "../../node_modules/@upgrowth/react-fulcrum/lib/mixins.scss";

a {
  color: $primary-text;
}
.Label.variant-primary {
  color: $primary-text;
}
.Avatar {
  border-radius: 0 !important;
}

.Button, .Button.variant-primary, .Button.variant-secondary {
  font-family: Prata;
  padding: 9px 16px 7px 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);

  &.dangerous {
    background-color: $dull-pink;
  }
}

.Modal {
  background: $dark;
  border: 1px solid $secondary;
  max-width: 1024px;
  margin: 0 auto;
  height: auto;
  bottom: auto;
  max-height: 80vh;
}

.Sidebar {
  padding-bottom: 24px;

  .NavMenu.variant-primary {
    background: $dark;

    .NavItem {
      color: $primary-text;
      align-items: center;

      &.active {
        background: $dark;
        color: $light;
      }
      &:hover {
        color: $light;
        background: $secondary;
      }
      &:focus {
        color: $light;
        background: $secondary;
      }
    }
  }
}

input.Input, textarea.Input {
  &.variant-primary {
    @include input-variant($palette-light, $palette-black);
  }
}

.Select {

  &.variant-primary {
    @include select-variant($palette-light, $palette-black);
    .FulcrumSelect__control {
      .FulcrumSelect__control--is-focused {
        box-shadow: none;
        border-bottom-color: get($palette-primary, 'normal', 'color');
      }
    }
  }

  &.variant-transparent {
    @include select-variant($palette-light, $palette-primary);
    .FulcrumSelect__control {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0px;
    }
    .FulcrumSelect__menu {
      background: $background;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.3), 0 4px 11px hsla(0, 0%, 0%, 0.3);
    }
    .FulcrumSelect__indicator-separator {
      display: none;
    }
    .FulcrumSelect__single-value {
      color: $light;
    }

    .FulcrumSelect__option {
      background: $dark;
    }
    .FulcrumSelect__option--is-selected {
      //background: inherit;
    }
  }

  &.variant-outline {

    @include select-variant($palette-light, $palette-dark);

    .FulcrumSelect__control {
      padding: 8px 16px;
      border: 1px solid $primary;
    }

    .FulcrumSelect__menu-list {
      padding-bottom: 0;
      padding-top: 0;
    }

    .FulcrumSelect__menu {
      background: get($palette-dark, 'normal', 'background');
      border: 1px solid $primary;
    }

    .FulcrumSelect__option--is-selected {
      background-color: get($palette-primary, 'normal', 'background');
      color: get($palette-light, 'normal', 'color');
    }

    .FulcrumSelect__dropdown-indicator {
      > svg {
        height: 16px;
        width: 16px;
      }
    }

    .FulcrumSelect__indicator-separator {
      margin-top: 0;
      margin-bottom: 0;
      display: none;
    }

  }

  &.size-medium {
    font-size: 14px;
  }

}
