.DFColumn {
  flex-basis: 50%;
  max-width: 50%;
  padding: 20px 80px;

  display: flex;

  flex-direction: column;
  justify-content: space-between;

  &.variant-skinny {
    max-width: 560px;
  }
  > .content {
    width: 100%;
    flex: 1;
  }

  > .actions {
    text-align: center;
    .Button {
      width: 240px;
    }
    > * {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.variant-fatty {
    max-width: 100%;
    p {
      max-width: 50%;
      margin: auto;
      margin-bottom: 32px;
    }
  }
}