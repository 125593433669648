@import "../imports";

.Page {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  //padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;

  &.size-small {
    padding: 80px;
    .content {
      max-width: 720px;
      margin: 0 auto;
    }

  }
  &.size-normal {
    padding: 80px;
    .content {
      margin: 0 auto;
      max-width: 920px;
    }
  }
  &.size-large {
  }

  .content {
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    > h1 {
      text-align: center;
      margin-bottom: 64px;
    }
    > h2 {
      text-align: center;
      margin-bottom: 64px;
    }
    > h3 {
      text-align: center;
      margin-bottom: 64px;
    }
  }

  // TODO - we should just make this a component
  .edit-mode-toggle {
    position: absolute;
    flex-direction: row-reverse;
    top: 0;
    right: 0;
    margin: 0;
    font-size: 14px;

    .react-toggle {
      margin: 0 8px;
    }
  }
}