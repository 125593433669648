@import '../imports';

.SingleColumn {
  max-width: 50vw;
  margin: 0 auto 56px auto;
  flex: 1;

  &.Form {
    .Field {
      padding: 16px;
      min-width: 560px;
      border: 1px solid transparent;
      transition: border-color 0.3s;

      .Label {
        @include Label-Text;
        color: $off-white;
        margin: 0;
        margin-bottom: 8px;
      }
      input {
        background-color: transparent;
        color: white;
        border: none !important;
        font-size: 16px;
        padding: 0;
        font: inherit;

        &::placeholder {
          color: $off-white;
          font-family: Raleway;
          font-size: 16px;
          font-weight: 300;
          font-style: italic;
        }
      }
      &.focus {
        border-color: $cadet-blue;
      }
    }
  }
}