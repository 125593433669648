@import "../../imports";

.ProjectRoutes {
  position: relative;
  flex: 1 1 auto;
  padding-left: $sidebar-width;
  transition: all 0.4s ease-out;

  .Sidebar {
    position: absolute;
    width: $sidebar-width;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all 0.4s ease-out;


    .show-sidebar {
      $hide-show-size: 24px;
      position: absolute;
      right: -$hide-show-size;
      width: $hide-show-size;
      //height: $hide-show-size;
      top: 12px;
      background: $dark;
      display: flex;
      flex:  0 0 $hide-show-size;
      justify-content: center;
      align-items: center;
      z-index: $zIndexSidebar+1;
      font-size: 26px;
      padding: 4px 0;
      opacity: 0.4;
      cursor: pointer;

      &:hover {
        opacity: 1;
        background: $secondary;
      }
    }
    > .NavMenu {
      align-items: stretch;

      .Select .FulcrumSelect__menu {
        z-index: 1000;
      }
    }

    .NavMenu {
      padding-top: 0;

      .header {
        display: flex;
        flex-direction: row;
        padding-right: 8px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          font-size: 26px;
        }
        .hide-sidebar {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          font-size: 26px;
          padding: 8px;
          opacity: 0.4;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }

        .user-link .Avatar {
          margin: 0;
        }
      }
      .NavItem {
        justify-content: space-between;
        font-size: 16px;
        font-family: Prata;
      }
      .inline-action {
        display: inline-flex;
        align-items: center;
        padding: 2px 6px;
        border-radius: 6px;
        font-size: 20px;
        color: $secondary;
        border: 1px solid rgba($secondary, 0.4);
        &:hover {
          color: $neutral;
          background: $secondary;
        }
      }
      > .NavMenu {
        flex: 0 0 auto;
        .NavItem {
          padding-left: 24px;
          font-size: 14px;
          font-family: inherit;
        }
      }

    }
  }
  .ProjectsList {
    padding: 0 8px;
    background: $background-dark;
  }

  &.hide-sidebar {
    padding-left: 18px;
    .Sidebar {
      left: -$sidebar-width;
    }
  }
}

