@import '../../imports';

.AboutNav.Navbar.variant-dark {
  position: absolute;
  left: 0;
  top: 57px;
  transition: background-color 0.3s;

  width: 100%;
  //padding: 37px;
  padding: 0 120px;

  display: flex;
  flex-direction: row;
  align-items: center;
  //transition: all 0.3s;
  background-color: transparent;

  &.stuck {
    position: fixed;
    top: 0;
    background-color: $pine-green;
  }

  a {
    text-decoration: none;
    color: white;
    &:hover, &.active {
      color: $accent-green;
    }
    &.active {
      text-decoration: underline;
      font-weight: 500;
    }

  }
  .NavMenu {
    flex: 1;
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: transparent;
  }

  .brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    .brand-name-header {
      margin-left: 16px;
      @include Card-Title;
    }
    text-decoration: none !important;
  }

  .items {
    margin-left: 60px;
    > * {
      margin-right: 24px;
      white-space: nowrap;
      transition: color 0.3s;

    }
  }

  .actions {
    padding: 8px 0;
    height: 100%;
  }

  .Button.variant-outline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    white-space: nowrap;

    height: 100%;
    border: solid 1px rgba($off-white, 0.5);
  }

  @include media("≤1100px") {
    top: 0;
    position: fixed;

    padding: 0 12px;
    padding-left: unquote('max(12px, env(safe-area-inset-left))');
    padding-right: unquote('max(12px, env(safe-area-inset-right))');

  }

  @include media("≥736px") {

    .dropdown {
      display: flex;
      flex-direction: row;
      flex: 1;

      &__trigger {
        opacity: 0;
        display: none;
        transform: translateX(100%);
      }

      &__content {
        display: flex;
        flex-direction: row;
        flex: 1;

        position: static;
      }

    }

    @include media("<850px") {
      top: 0;
      position: fixed;

      .brand-name-header {
        display: none;
      }

      .items {
        margin-left: 12px;
      }
    }
  }


  justify-content: space-between;


  @include media("<736px") {
    top: 0;
    position: fixed;

    .brand {
      position: relative;
      z-index: 10;
    }

    .dropdown {
      &__trigger {
        cursor: pointer;
        position: relative;
        z-index: 10;
      }
      &__content {
        transition: bottom 0.3s, padding 0.3s;
        position: fixed;
        bottom: 100%;
        overflow: hidden;
        left: 0;
        top: 0px;
        width: 100%;
        background-color: $pine-green;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .NavMenu {
          flex-direction: column;
        }
        a {
          display: block;

        }
        .items {
          margin: 0;
          width: 100%;
          a {
            font-size: 22px;

            padding: 20px 0;
          }
          padding-bottom: 80px;
        }

        .actions {
          height: auto;
        }
      }

      &--active .dropdown__content {
        bottom: 0;
        padding: 80px 40px;

      }
    }

    @include media()
  }
}