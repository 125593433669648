/* Color palette */

$cadet-blue: #487d8f;
$dark-grey-blue: #29454e;
$pine-green: #0b262f;
$off-white: #f0f0ee;
$dark-sage: #67725a;
$light-moss-green: #9dc47e;
$very-light-brown: #c7b299;

$dull-pink: #d979a3;

/* Text styles */

@mixin Body-Text {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 300;
  color: $off-white;
}

@mixin Button-Text {
  font-family: Prata;
  font-size: 16px;
  text-align: center;
  color: $off-white;
}

@mixin Heading-Text {
  font-family: Prata;
  font-size: 40px;
  text-align: center;
  color: $off-white;
}

@mixin Scrubber-Text {
  font-family: RobotoMono;
  font-size: 20px;
  text-align: center;
  color: $off-white;
}

@mixin Label-Text {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 300;
  color: $off-white;
}

@mixin Card-Title {
  font-family: Prata;
  font-size: 20px;
  color: $off-white;
}

@mixin Content-Page-Header-Mobile {
  font-family: Prata;
  font-size: 48px;
  text-align: center;
  color: $off-white;
}

@mixin Mobile-Header-Text {
  font-family: Prata;
  font-size: 24px;
  text-align: center;
  color: $off-white;
}

@mixin Content-Page-Header {
  font-family: Prata;
  font-size: 80px;
  text-align: center;
  color: $off-white;
}