@import '../../imports';

.MomentList {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  > .moments {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .moment-controls {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    justify-content: center;
  }

  .TagView {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .table-container {
      flex: 1 1 auto;
    }
  }
}