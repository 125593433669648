@import "../../imports";

.User {

  .Page {
    align-items: center;
  }
  .Button {
    margin: 64px auto;
  }

}