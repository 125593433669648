.RenderEntity {

  .tag-row {
    width: 100%;
    align-items: center;
  }


  &.Form {

    .FormRow {
      justify-content: space-between;
      flex-wrap: wrap;
      .Field {
        flex: 1 1 48%;
      }
    }


  }

}
