@import "../../imports";

.CreateSegment {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .SegmentOptions {
    display: flex;
    flex-wrap: wrap;

    .Card {
      &.selected {
        border: 1px solid $primary;
        background: $secondary;
      }
    }
  }
}