@import '../imports.scss';
.Loading {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  .Icon {
    font-size: 40px;
    color: $primary;
    animation: spin 1s infinite;

  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
}
