@import '../../imports';

.Tag {

  &.MultiSelectTagViewer {
    span {
      display: inline-block;
      background: $primary;
      padding: 4px 8px;
      margin: 2px 4px 2px 0px;
      white-space: nowrap;
    }
  }
}
