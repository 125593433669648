@import "../../imports";

.AddTag {
  .dropdown__content {
    background: $background;
    border: 1px solid $secondary;
    padding: 18px;
    z-index: $zIndexModal - 1;

    .close {
      position: absolute;
      right: 12px;
      top: 12px;
    }

    a {
      display: inline-block;
      margin: 4px 8px;
      padding: 4px 12px;
      background: $secondary;
      border-radius: 16px;
      color: $text;
      text-decoration: none;

    }
  }
}

