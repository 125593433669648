@import "../../imports.scss";

.PageHelp {
  .nav-action {
    display: block;
    padding: 4px 16px;
    font-size: 16px;
    text-decoration: none;
    color: $accent-green;

    &:hover {
      background: $secondary;
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .content {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexModal - 1;
    background: rgba($dark, 0.8);
    overflow-y: auto;
    display: none;

    &.visible {
      display: flex;
    }

    .help-content {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;

      color: $light;
      padding: 64px;

      > h1 {
        text-align: center;
        margin-bottom: 64px;
      }
    }

    .close-action {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 32px;
      right: 32px;
      font-size: 40px;
      color: $accent-green;
      text-decoration: none;

      .label {
        margin-top: -6px;
        font-size: 14px;
      }
    }
  }
}