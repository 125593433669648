@import "../../imports";

.TagList {
  margin: 20px 0;

  &.editing {
    .TagRow {
      padding: 8px 16px;

      &:hover {
        background: rgba($primary, 0.2);
      }
    }
  }
}

.TagRow {
  display: flex;
  align-items: center;
  padding: 8px 0px;

  > div {
    flex: 1 1 auto;
  }
  > a {
    flex: 0 0 auto;
    margin: 0 8px;
  }

}