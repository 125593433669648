@import '../../imports';

.InsightEditor {
  .insight-name, .insight-description {
    margin-top: 16px;
    text-align: left;
  }

  .tag-list {

    .TagEditor {
      flex: 0 0 45%;
      margin-bottom: 24px;
    }

    .Tag {
      flex: 0 0 45%;
    }
  }

  .Button {
    margin-top: 32px;
  }
}
