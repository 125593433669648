@import '../../imports';

.Link {
  display: inline-flex;
  align-items: center;
  margin: 4px 12px 4px 0;
  padding: 8px 16px;
  background: $primary;
  color: $light;
  a {
    color: inherit;
    text-decoration: none;
  }
  .remove {
    color: $light;
    margin-left: 8px;
  }
}
