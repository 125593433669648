@import "../imports.scss";
@import "../../node_modules/@upgrowth/firelight/lib/index.scss";

.FirelightView {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;


  .MarkdownEditor > .CodeMirror { @include input-variant($palette-light, $palette-black); }

  .pages-content {
    padding: 12px;
  }

}
.Admin {


}