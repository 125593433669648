@import "../imports";

.CardGrid {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, $card-width);
  grid-auto-rows: auto;
  justify-content: center;


  .Card {
    margin: 0;
  }
}