@import "../../imports";

$narrow_width: 560px;

.Cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 80px;
  flex-wrap: wrap;
  padding: 0 40px;

  .Cards-section {
    border: 1px solid $cadet-blue;
    width: 200px;
    margin: 0 16px;
    padding: 24px;
    margin-bottom: 24px;

    h3 {
      font-weight: normal;
      font-family: Prata;
      font-size: 20px;
      color: $off-white;
    }
  }
}

.LinkButton.Button {
  margin: 80px auto;
}

.Block {
  max-width: $narrow_width;

  margin-top: 40px;

  h1 {
    margin: 80px 0;
    text-align: center;
  }
  h2 {
    margin: 80px 0;
    text-align: center;
  }
  h3 {
    margin: 80px 0;
    text-align: center;
  }

  p {
    font-size: $font-size-large;
  }


  @include media("≤850px") {
    padding: 0 40px;
  }
}

.Image {
  margin-top: 120px;
  @include media("≤850px") {
    height: 160px;
    margin-top: 40px;
  }
}

$chart-border: solid 1px rgba($cadet-blue, 0.5);
.PricingChart {
  max-width: $narrow_width;
  margin-top: 80px;
  //border: $chart-border;

  .row {
    display: flex;
    .cell {
      flex: 1 0 0;
      overflow: hidden;
      padding: 17px;
      border-top: $chart-border;
      border-left: $chart-border;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      em {
        color: inherit;
        font-size: 20px;
      }

      text-align: center;
      &:nth-child(2) {
        background-color: $dark-grey-blue;
      }
      p {
        margin: 0;
        padding: 0;
      }

      &:last-child {
        border-right: $chart-border;

      }

    }
    &:first-child {
      @include Card-Title;
      .cell {
        padding: 24px;
      }
    }

    &:last-child {
      border-bottom: $chart-border;

      .cell {
        color: $dull-pink;
        font-size: 24px;
      }
      .cell:nth-child(2) {
        color: $light-moss-green;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        p {
          margin-right: 5px;
        }
        &:after {
          content: "per person\Aper month";
          white-space: pre;

          display: block;
          font-size: 12px;
        }
      }
    }
  }

  @include media("≤850px") {
     margin-top: 40px;
    max-width: 100%;
  }

}