@import "../imports";

.SimpleDropdown {
  position: relative;

  .dropdown__trigger {
    display: flex;
    flex: 0 0 auto;
    padding: 0 8px;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
  }


  .dropdown__content {
    z-index: 10;
    min-width: 200px;
    background: $dark;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);
    padding: 16px 24px;
    color: $primary;
    top: 104%;

    > a {
      display: block;
      color: $accent-green;
      margin: 4px 0;
      cursor: pointer;
    }

    .header {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      font-size: 12px;
      margin-bottom: 12px;

      h5 {
        display: inline-flex;
        flex: 1 1 auto;
        margin: 0;
      }
      .close {
        color: $primary;
        font-size: 18px;
      }
    }

  }
  &.dropdown--active .dropdown__content {
    display: flex;
    flex-direction: column;
  }

  &.variant-outline {
    background: get($palette-dark, 'normal', 'background');
    border: 1px solid get($palette-primary, 'normal', 'color');
    &:hover {
      background: rgba($primary, 0.2);
    }
    .dropdown__trigger {
      padding: 8px 16px;
      color: get($palette-light, 'normal', 'color');
    }
    .dropdown__indicator {
      margin-left: 16px;
    }
  }
}

