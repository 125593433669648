@import "../imports";

.CustomModal {
  width: 552px;

  &:focus {
    outline: none;
  }

  margin: auto;
  background-color: $pine-green;
  padding: 24px;
  padding-top: 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);

  h3 {
    @include Label-Text;
    margin: 0;
  }

  .actions {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    > * {
      flex: 1 1 auto;
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4) !important;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}