@import '../../imports';

.Segments {
  .introduction {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 480px;

    h1 {
      text-align: center;
    }

    .Button {
      align-self: center;
      margin-top: 48px;
    }

  }
}
