@import '../imports';

.LogIn {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 80px 0;

  h1 {
    margin-bottom: 100px;
  }
  .notice {
    background-color: $dark-grey-blue;
    border: 1px solid rgba($off-white, 0.5);
    padding: 0 12px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    &:after {
      $box-size: 20px;
      position: absolute;
      width: $box-size;
      height: $box-size;
      background-color: rgba($off-white, 0.5);
      display: block;
      content: "";
      top: -$box-size/2;
      left: -$box-size/2;
      transform: rotate(45deg);
    }

    h2 {
      text-align: center;
      @include Card-Title;
    }
    &.warning {
      background-color: rgba($dull-pink, 0.4);
      border-color: $dull-pink;
      &:after {
        background-color: $dull-pink;
      }
    }
    p {
      margin: 12px 0;
    }
    .Button {
      margin: 12px 0;
    }
    li {
      margin-bottom: 12px;
    }
  }
  a {
    color: $light-moss-green;
  }
}