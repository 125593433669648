@import "../../imports";

.CreateResearchItem {
  .upload-button {
    border: 1px solid $cadet-blue;
    display: block;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 32px;

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(45deg, #487d8f 40%, #0b262f 40%, #0b262f 50%, #487d8f 50%, #487d8f 90%, #0b262f 90%, #0b262f 100%);
      background-size: 7.07px 7.07px;
      transition: all 0.3s;
      z-index: -1;
    }
  }
}