@import '../../imports';

.TagView {

  .toggle-tag-view {
    margin: 0;
  }

  .switch-view {
    position: relative;
    display: inline-flex;
    flex: 0 0 128px;
    height: 39px;

    .icon {
      top: 7px;
      left: 6px;
      position: absolute;
      z-index: 1;
      color: $primary;
      font-size: 24px;
    }
    .Select {
      display: inline-flex;
      flex: 0 0 120px;

      .FulcrumSelect__control {
        padding-left: 28px;
        padding-right: 8px;
      }
    }
  }

  .Button {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid $primary;
  }
}
