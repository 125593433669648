@import "../imports";

.Card {
  //display: flex;
  //flex-direction: column;
  width: $card-width;
  flex: 0 0 $card-width;
  //align-items: stretch;
  min-height: $card-width;
  border-radius: 1px;
  border: 1px solid rgba($primary, 0.75);
  padding: 24px;

  h1, h2, h3 {
    text-align: center;
  }

  .card-icon {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    color: $neutral;
    a {
      color: $neutral;
    }
  }
}
