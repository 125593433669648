@import "~react-simple-dropdown/styles/Dropdown.css";
@import "../imports";

.Tabs {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 0 0 auto;

  .NavItem {
    position: relative;
    flex: 0 0 auto;
    margin-right: 12px;
    font-size: 16px;
    text-decoration: none !important;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 70%;
      left: 8px;
      right: 8px;
      border-bottom: 3px solid transparent;
    }

    &.active:after {
      border-bottom-color: $primary;
    }
    &:focus:after, &:hover:after {
      border-bottom-color: rgba($primary, 0.5);
    }
  }
}