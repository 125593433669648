@import "../../imports";

.Moment {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .Textarea {
    background: transparent;
    color: inherit;
    border-radius: 1px;
    border: 1px solid transparent;
    @include Body-Text;

    &:focus {
      border-color: $cadet-blue;
    }
  }

  .Field > label, label {
    color: $off-white;
    @include Label-Text;

  }


  .Field {
    textarea {
      margin-top: -38px;
      padding-top: 38px;
      padding-bottom: 16px;
    }
  }

  > .Button {
    align-self: flex-end;
  }

}