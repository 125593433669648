@import "../../imports";

.TagTable {
  $foreground-color: #DDD;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  color: $foreground-color;

  .table-container {
    flex: 1 0 auto;
    max-width: 100%;
    overflow-x: scroll;

    table {
      width: 100%;
      border-collapse: collapse;

      td {

        &:first-child {
          padding-left: 8px;
        }

        min-width: 120px;

        &.actions {
          width: 20px;
          min-width: 0;
          padding-left: 0;
          padding-right: 0;

          &.bulk {
            width: 54px;
          }

          .actions-inner {
            display: flex;
            align-items: center;
          }

          .Input {
            padding: 0px;
            margin: 0;

            input {
              width: 18px;
              height: 18px;
              margin-right: 0;

              &:after {
                border-width: 2px;
                transform: translate(-1px, 1px) rotate(-45deg);
              }
            }
          }
        }
      }

      thead {
        td {
          font-size: 12px;
          padding: 8px;
          color: $accent-green;
          border-bottom: 1px solid $accent-green;
        }
      }

      tbody {
        tr {
          transition: all .1s ease-in;
          border: 1px solid transparent;

          td {
            padding: 8px;
            border-bottom: 1px solid get($palette-primary, 'normal', 'color');

            h5 {
              font-size: 14px;
              margin: 0;
            }
          }
        }
      }
    }
  }

  &.inset {
    .table-container {
      padding: 0 30px;
    }
    .TagViewControls {
      padding: 24px;
    }
  }
}

