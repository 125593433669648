@import "../../imports";


.EntityCard {
  $foreground-color: #DDD;

  border: 1px solid $primary;
  padding: 8px 16px;
  margin: 0 0 16px 0;
  overflow: visible;
  background: get($palette-dark, 'normal', 'background');

  &:hover {
    background: rgba(get($palette-primary, 'normal', 'color'), 0.1);
    .tag-name {
      text-decoration: underline;
    }
  }

  &.selected {
    background: rgba(get($palette-primary, 'normal', 'color'), 0.2);
  }

  .lead-container {
    align-items: flex-start;
    padding-top: 8px;

    .ActionsDropdown {
      margin-top: 4px;
    }
  }
  .tag-name {
    margin: 0 0 8px 0;
    font-family: Raleway, sans-serif;
    font-size: $font-size-small;
    color: $foreground-color;
    text-decoration: none;
    cursor: pointer;
  }
  .tag-list {
    padding-top: 8px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    > .Tag {
      flex: 0 0 45%;
      font-weight: 500;

      .Label {
        font-size: $font-size-smallest;
        color: $primary
      }

      .tag-card-value {
        margin: 4px 0 16px 0;
        font-size: $font-size-smallest;
        color: $foreground-color;
      }

    }
  }

  .edit-profile {
    text-transform: capitalize;
  }
}
